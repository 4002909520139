import React from 'react';

const ContactForm = () => {
    return (
        <div className="col-lg-6 contact_grid_right">
            <form method="post" action="https://formspree.io/info@pmgcpa.com">
                <div className="row contact_top">
                    <div className="col-sm-6">
                        <input onFocus="messageOnFocus(this)" onblur="messageOnBlur(this)" type="text"
                            name="Name" placeholder="Name" required />
                    </div>
                    <div className="col-sm-6">
                        <input onFocus="messageOnFocus(this)" onblur="messageOnBlur(this)" type="text"
                            name="Company" placeholder="Company (Optional)" required="" />
                    </div>
                    <div className="col-sm-6 mt-2">
                        <input onFocus="messageOnFocus(this)" onblur="messageOnBlur(this)" type="email"
                            name="Email" placeholder="Email" required />
                    </div>
                    <div className="col-sm-6 mt-2">
                        <input onFocus="messageOnFocus(this)" onblur="messageOnBlur(this)" type="tel"
                            name="Phone" placeholder="Phone Number" required />
                    </div>
                </div>
                <textarea placeholder="Message..." name="Message" onfocus="messageOnFocus(this)"
                    onblur="messageOnBlur(this)" required></textarea>
                <input className="mt-2" type="submit" value="Send" />
                <div className="clearfix"> </div>
            </form>
        </div>
    );
}

export default ContactForm;
import React from 'react';

import ContactHeader from './components/ContactHeader';
import ContactForm from './components/ContactForm';
import ContactItems from './components/ContactItems';

const Contact = (props) => {
    if (props.isLoaded) {
        let contactProps = props.result[0].Contact;

        return (
            <section className="contact py-5" id="contact">
                <div className="container py-lg-5">
                    <ContactHeader { ...contactProps} />

                    <div className="row contact-top">
                        <ContactItems { ...contactProps} />
                        <ContactForm { ...contactProps} />
                    </div>
                </div>
            </section>
        );
    } else {
        return (
            <section id="home">
                <div class="loader" style={{ marginTop: '30vh' }}></div>
            </section>
        );
    }
}

export default Contact;
import React from 'react';

import ServiceItem from './components/ServiceItem'

const Services = (props) => {
    let servicesProps;
    if (props.isLoaded) {
        servicesProps = props.result[0].services;
        let list = servicesProps.List;
        let listHalfLen = Math.ceil(list.length / 2);
        let list2 = list.splice(0, listHalfLen)

        return (
            <section className="about" id="services">
                <div className="py-5" style={{ backgroundColor: 'rgba(0, 42, 123, 0.71)' }}>
                    <div className="container">
                        <div className="inner-sec-w3ls-pyt py-lg-5 py-3 text-center">
                            <h3 style={{ marginBottom: '15px !important' }} className="title text-center mb-lg-5 mb-3 px-lg-5">
                                <span className="sub-title">{servicesProps.Title1}</span>
                                {servicesProps.Title2}
                            </h3>
                            <p className="main_p4 mt-4 mb-4 pt-2 text-center mx-auto text-white">
                                {servicesProps.Description}

                            </p>


                            <div id="all-services" className="mt-4">
                                <h4 className="mb-2 text-white">List of All Services</h4>
                                <div className="row">
                                    <div className="col-md-6 text-center mt-4">
                                        {list.map(function (text, index) {
                                            return <ServiceItem key={index}>{text}</ServiceItem>;
                                        })}

                                    </div>
                                    <div className="col-md-6 text-center mt-4">
                                        {list2.map(function (text, index) {
                                            return <ServiceItem key={index}>{text}</ServiceItem>;
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    } else {
        servicesProps = {};

        return (
            <section id="home">
                <div class="loader" style={{marginTop : '30vh'}}></div>
            </section>);
    }




}

export default Services;
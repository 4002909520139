import React from 'react';
import {NavLink} from 'react-router-dom';

const Footer = () => {
    return (
        <div id="footer" className="cpy-right-w3layouts-pvt text-center py-5">
            <p className="mb-3"> © 2019. PMG Certified Public Accountants, LLP</p>
            <div className="container">
                <div className="row text-white text-sp-1">
                    <div className="col-md-2 offset-md-3"><NavLink to="/" exact activeClassName="footer-active">Home</NavLink></div>
                    <div className="col-md-2"><NavLink to="/services" exact activeClassName="footer-active">Services</NavLink></div>
                    <div className="col-md-2"><NavLink to="/team" exact activeClassName="footer-active">Team</NavLink></div>
                    <div className="col-md-2 offset-md-3"><NavLink to="/contact" exact activeClassName="footer-active">Contact</NavLink></div>
                    <div className="col-md-2"><NavLink to="/paybills" exact activeClassName="footer-active">Paybills</NavLink></div>
                    <div className="col-md-2"><a target="_blank" rel="noopener noreferrer" href="https://pmgcpa.sharefile.com/Authentication/Login">PMG Sharefile</a></div>
                </div>
            </div>

            <a href="#home" className="move-top scroll"><span className="fa fa-angle-up mt-4" aria-hidden="true" /></a>
        </div>
    );
}

export default Footer;
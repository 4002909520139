import React from 'react';

import TeamHeader from './components/TeamHeader'
import TeamBioLarge from './components/TeamBioLarge'


import group1 from '../../images/group 7-min.jpg'
import conf1 from '../../images/conference room-min.jpg'
import roblogo from '../../images/Front-min.JPG'

const Team = (props) => {

  let teamProps;
  let teamList;
  if (props.isLoaded) {
    teamProps = props.result[0].Team;
    teamList = teamProps.Biographies;

    return (
      <section>
        <div id="team">
          <div className="container py-5" id="team-wrapper">
            <TeamHeader />

            {teamList.map(function (profile, index) {
              let orientation;
              if (index % 2 === 0) {
                orientation = "left";
              } else {
                orientation = "right";
              }

              return <TeamBioLarge photo={profile.Photo} orientation={orientation} name={profile.Name}
                position={profile.Position} desc={profile.Bio} />;
            })}

            <div className="row mt-5">
              <div className="col-sm-12">
                <img width="100%" src={group1} />
              </div>
              <div className="col-sm-6 mt-3">
                <img width="100%" src={conf1} />
              </div>
              <div className="col-sm-6 mt-3">
                <img width="100%" src={roblogo} />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  } else {

    return (
      <section id="home">
        <div class="loader" style={{ marginTop: '30vh' }}></div>
      </section>);
  }


}

export default Team;
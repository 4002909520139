import React from 'react';
import { BrowserRouter as Router, Route, Switch, NavLink } from "react-router-dom";

import logo from '../../../images/logo.jpg'
import group9 from '../../../images/group 9-min.jpg'

class HomePanel1 extends React.Component {

    render() {
        //console.log(this.state.result)
        return (
            <div id="main-panel-1" >
                <div className="banner-info-wthree">
                    <img id="logo2" src={logo} />
                    <h2>{ this.props.isLoaded ? this.props.Title1:  ""}</h2>
                    <h3>{ this.props.isLoaded ? this.props.Title2:  ""}</h3>


                    <div className="container pt-4" id="about">
                        <h3 className="title text-center mb-lg-5 mb-3 px-lg-5 text-color-2"><span className="sub-title text-color-1">{this.props.Panel1.Title1}</span>{this.props.Panel1.Title2}</h3>
                        <div className="row mb-5">
                            <div className="col-md-6">
                                <img width="100%" src={group9} />
                            </div>
                            <div className="col-md-6 text-center">
                                <p className="text-black">
                                    { this.props.Panel1.Description }
                                </p>
                                <NavLink id="btn-team-2" className="btn-main mt-3" to="/team" exact>View Our Team</NavLink>
                            </div>
                            <NavLink id="btn-team-1" className="btn-main mt-3" to="/team" exact>View Our Team</NavLink>
                        </div>
                    </div>

                    <a href="#experience" className="scroll">
                        <div className="icon-scroll">
                            <div className="mouse">
                                <div className="wheel"></div>
                            </div>
                            <div className="icon-arrows">
                                <span></span>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        );
    }
}

export default HomePanel1;
import React from 'react';

const ContactListItem = (props) => {
    return (
        <li className={props.mtNone ? '' : 'mt-3'}>
            <div className="row">
                <div className="col-3">
                    <i className={"fa " + props.icon}></i>
                </div>
                <div className="col-9">
                    <h6>{props.header}</h6>
                    
                        {props.custom ? 
                            props.custom :
                            (props.href ? 
                                <p><a href={props.href}>{props.contact}</a></p> :
                                <p>{props.contact}</p>
                            )
                        }
                    
                </div>
            </div>
        </li>
    );
}

export default ContactListItem;
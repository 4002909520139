import React from 'react';

import PaybillsHeader from './components/PaybillsHeader';
import PaybillsForm from './components/PaybillsForm';
import PaybillsSummary from './components/PaybillsSummary';

class Paybills extends React.Component {
    render() {
        return (
            <section className="contact py-5" id="paybills">
                <PaybillsHeader />
                
                <div className="container text-center" >
                    <div className="contact-top ">
                        <PaybillsSummary />
                        <PaybillsForm />
                    </div>
                </div>
            </section>
        )
    }
}

export default Paybills;
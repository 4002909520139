import React from 'react';

import check from '../../../images/check.svg';

const ServiceItem = (props) => {
    return (
        <p className="service-label sub-title-team">
            <img className="check" src={check} />
            {props.children}
        </p>
    );
}

export default ServiceItem;
import React from 'react';

import ContactListItem from './ContactListItem.js';

const ContactItems = () => {
    return (
        <div className="col-lg-6 contact_grid_left">
            <ul className="list-unstyled">
                <ContactListItem
                    mtNone
                    icon="fa-home"
                    header="Address"
                    custom={
                        <p className="mb-none">
                            PMG Certified Public Accountants, LLP
                                        <br />135 Bedford Road
                                        <br />Armonk, NY 10504
                                        <br />
                            <a style={{ color: "#007bff" }} href="http://maps.apple.com/?q=135+Bedford+Road+Armonk+NY" rel="noopener noreferrer" target="_blank">View Directions</a>
                        </p>
                    }
                />
                <ContactListItem icon="fa-envelope" header="Email" href="mailto:info@example.com" contact="info@pmgcpa.com" />
                <ContactListItem icon="fa-phone" header="Phone" href="tel:1-914-273-8300" contact="(914) 273-8300" />
                <ContactListItem icon="fa-fax" header="Fax" contact="(914) 273-8301" />
            </ul>
        </div>
    );
};

export default ContactItems;
import React from 'react';
import { BrowserRouter as Router, Route, Switch, NavLink } from "react-router-dom";

import ServiceListItem from './ServiceListItem';

class HomePanel3 extends React.Component {

    render() {
        return (
            <div id="experience" className="container">
                <h3 className="title text-center mb-lg-5 mb-3 px-lg-5 text-color-2"><span className="sub-title text-color-1">{ this.props.isLoaded ? this.props.Panel2.Title1:  ""}</span>{ this.props.isLoaded ? this.props.Panel2.Title2:  ""}</h3>
                <div className="row mb-5">
                    <div className="col-md-6 text-center">
                        <p className="text-black">
                            {/* this.state.isLoaded ? this.state.result[0].header2 : "" */}
                            { this.props.Panel2.Description}
                            </p>
                    </div>
                    <div className="col-md-6 text-center mt-3">
                        <div className="bg-main p-3 text-white d-inline-block">
                            <h4 id="inc" className="d-inline-block">{ this.props.Panel2.Title3 }</h4><br />
                            
                            {  this.props.Panel2.List.map(function(name, index){
                                return <ServiceListItem key={ index } text={name} />;
                            }) }
                          
                        </div>
                        <NavLink id="btn-team-2" className="btn-main mt-3" to="/services" exact>View All Services</NavLink>
                    </div>
                    <NavLink id="btn-team-1" className="btn-main mt-3" to="/services" exact>View All Services</NavLink>
                </div>
            </div>
        );
    }

}

export default HomePanel3;
import React from 'react';
import { BrowserRouter, Route, Switch, NavLink } from "react-router-dom";

import Services from './pages/services/Services';
import Home from './pages/home/Home';
import Contact from './pages/contact/Contact';
import Team from './pages/team/Team';
import Paybills from './pages/paybills/Paybills';

import Head from './components/Head';
import Navigation from './components/Navigation';
import ShowTheLocation from './components/ShowTheLocation';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';

// CSS imports
import './App.css';
import './css/bootstrap.css';
import './css/style.css';
import './css/all.css';


class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      result: ''
    };
  }

  componentDidMount() {
    fetch("https://api.pmcollp.com/configs")
      .then(res => res.json())
      .then(
        (result) => {
          console.log(result)
          this.setState({
            isLoaded: true,
            result: result
          });

        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          this.setState({
            isLoaded: false,
            error
          });
        }
      )
  }
  render() {
    return (
      <BrowserRouter>
        <ScrollToTop>
          <Navigation />

          <Switch>
            <Route path="/" exact render={(props) => <Home {...this.state} />}/>
            <Route path="/services" exact render={(props) => <Services {...this.state} />} />
            <Route path="/contact" exact render={(props) => <Contact {...this.state} />} />
            <Route path="/team" exact render={(props) => <Team {...this.state} />} />
            <Route path="/paybills" exact render={(props) => <Paybills {...this.state} />} />
          </Switch>

          <Footer />
        </ScrollToTop>

      </BrowserRouter>
    );
  }

}

export default App;

import React from 'react';

const PaybillsSummary = () => {
    return (
        <p className="main_p4 mt-4 mb-4 pt-2 text-center mx-auto">
            Please Enter The Amount Due to PMG Certified Public Accountants, LLP in the box below then press
            "Continue to Payment Gateway". You will be redirected to our Payment Processing Form.
            <br /><br />
            Amounts will be credited against past due invoices. If you are unsure of your amount due,
            Please contact Adriana at 914-273-8300 at extension 116. Once we receive notification of a
            payment we will send a statement reflecting your new balance.
        </p>
    );
}

export default PaybillsSummary;
import React from 'react';
import { BrowserRouter as Router, Route, Switch, NavLink } from "react-router-dom";

const HomePanel5 = () => {
    return (
        <div className="container my-5" id="about">
            <h3 className="title text-center px-lg-5 text-color-2">
                <span className="sub-title text-color-1">Want to get in touch?</span>
            </h3>
            <NavLink className="btn-main mt-3" to="/contact" exact>Contact Us</NavLink>
        </div>
    );
}

export default HomePanel5;
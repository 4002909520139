import React from 'react';

const TeamHeader = () => {
    return (
        <div className="inner-sec-w3ls-pyt speak text-center">
            <h3 className="title text-center d-inline-block text-color-2"><span
                className="sub-title text-color-1">Who we are</span>Our Team</h3>
        </div>
    );
}

export default TeamHeader;
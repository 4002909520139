import React, { Component } from 'react';

class ServiceListItem extends Component {
    constructor(props) {
        super(props);
    }

    render(props) {
        return (
            <React.Fragment>
                <div className="text-center d-inline-block">
                    <img className="check" src="/static/media/check.a15a44bf.svg"></img>
                    <p className="d-inline ml-1">{this.props.text}</p>
                </div>
                <br />
            </React.Fragment>

        );
    }
}

export default ServiceListItem;
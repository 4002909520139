import React from 'react'

const PaybillsForm = () => {
    return (
        <form className="col-lg-12 contact_grid_right my-0" action="https://www.eprocessingnetwork.com/cgi-bin/wo/order.pl" method="post">
            <p id="dollar">$</p>
            <input type="hidden" name="QB" value="" />
            <input type="hidden" name="ItemQty" value="1" />
            <font face="Verdana" size="-1"></font>
            <input id="item-cost" type="text" name="ItemCost" size="8" maxlength="8" />
            <input type="hidden" name="ItemDesc" value="Invoiced Total" size="40" maxlength="40" />
            <input id="payment-submit" type="submit" value="Continue To Payment Gateway" />

            <input type="hidden" name="ePNAccount" value="0914755" />
            <input type="hidden" name="ReturnToURL" value="http://www.pmgcpa.com" />
            <input type="hidden" name="BackgroundColor" value="White" />
            <input type="hidden" name="TextColor" value="Black" />
            <input type="hidden" name="LogoURL" value="http://www.gcllp.com//index.1.jpg" />
        </form >
    );
}

export default PaybillsForm;
import React from 'react';

import HomePanel1 from './components/HomePanel1';
import HomePanel3 from './components/HomePanel3';
import HomePanel4 from './components/HomePanel4';
import HomePanel5 from './components/HomePanel5';

const Home = (props) => {
    let homeProps;
    if (props.isLoaded) {
        homeProps = props.result[0].Home;

        return (
            <section id="home">
                <HomePanel1 {...homeProps} isLoaded={props.isLoaded} />
                <HomePanel3 {...homeProps} isLoaded={props.isLoaded} />
                <HomePanel4 {...homeProps} isLoaded={props.isLoaded} />
                <HomePanel5 {...homeProps} isLoaded={props.isLoaded} />
            </section>);
    } else {
        homeProps = {};

        return (
            <section id="home">
                <div class="loader" style={{marginTop : '30vh'}}></div>
            </section>);
    }
}

export default Home;
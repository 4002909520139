import React from 'react';

const ContactHeader = () => {
    return (
        <React.Fragment>
            <h3 className="title two text-center"><span className="sub-title">Find Us</span> Contact Info</h3>
            <div className="text-center">
                <p className="main_p4 mt-4 mb-4 pt-2 text-center mx-auto">Feel free to contact us by phone or email, or send
                        us an inquiry through our form below.</p>
            </div>
        </React.Fragment>
    );
};

export default ContactHeader;
import React from 'react';

class HomePanel4 extends React.Component {
    render() {
        console.log(this.props)
        return (
            <div className="container mw-100 mb-4">
                <h3 className="title text-center mb-lg-5 mb-3 px-lg-5 text-color-2"><span className="sub-title text-color-1">Why choose us?</span>Our Difference</h3>
                <div className="row">
                    <div className="col-md-6 mt-4 slant-sm">
                        <div id="info-box-1" className="width-100-c">
                            <h3>{this.props.Panel3.Title1}</h3>
                            <p>{this.props.Panel3.Description1}</p>
                        </div>
                    </div>
                    <div className="col-md-6 my-4 slant-sm">
                        <div id="info-box-2" className="width-100-c">
                            <h3>{this.props.Panel3.Title2}</h3>
                            <p>{this.props.Panel3.Description2}</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default HomePanel4;